<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Stat,
  },
  data() {
    return {
      statData: null,

      plans: null,
      plan: null,
      monthlies: {
        loading: true,
        errored: false,
        total: 0,
        fields: [
          { key: "id", label: "#", sortable: true },
          { key: "start", label: "Início", sortable: true },
          { key: "end", label: "Final", sortable: true },
          { key: "plan", label: "Plano", sortable: true },
          { key: "status", label: "Status", sortable: true },
          { key: "order", label: "", tdClass: 'text-right' },
        ],
        items: null,

        sortBy: "name",
        sortDesc: false,
        currentPage: "1",
        perPage: "25",
      },

      buy: {
        modal: false,
        loading: false,
        id: '',
        name: '',
        description: '',
        value: '',
      },

      renew: {
        modal: false,
        loading: false,
        id: '',
        name: '',
        description: '',
        value: '',
      }
    };
  },
  methods: {
    getPlans() {
      this.plans = null;

      api
        .get('plans')
        .then((response) => {
          if (response.data.status == 'success') {
            this.plans = response.data.list;
          }
        })
    },
    getPlan() {
      this.plan = null;
      this.statData = null;

      api
        .get('plans/current')
        .then((response) => {
          if (response.data.status == 'success') {
            this.plan = response.data.plan

            if (this.plan.status == 'active') {
              this.statData = [
                {
                  title: "Plano Atual",
                  value: this.plan.name,
                },
                {
                  title: "Status",
                  value: this.plan.status,
                },
                {
                  title: "Vencimento",
                  value: this.plan.due_date
                }
              ]
            } else {
              this.statData = [
                {
                  title: "Plano Atual",
                  value: this.plan.name,
                },
                {
                  title: "Status",
                  value: this.plan.status,
                },
              ]
            }
          } else {
            this.statData = [
              {
                title: "Plano Atual",
                value: "Cliente",
              },
              {
                title: "Status",
                value: "inactive",
              },
            ];
          }
        })
    },
    getMonthlies() {
      this.monthlies.total = 0;
      this.monthlies.items = null;

      api
        .get('plans/monthlies')
        .then((response) => {
          if (response.data.status == 'success') {
            this.monthlies.total = response.data.list.length;
            this.monthlies.items = response.data.list;
          }
        })
    },
    showBuy(plan) {
      this.buy.modal = false;
      this.buy.modal = true;
      this.buy.id = plan.id;
      this.buy.name = plan.name;
      this.buy.description = plan.description;
      this.buy.value = plan.value;
    },
    buyPlan() {
      if (this.buy.id) {
        this.buy.loading = true;

        api
          .post("plans", {
            id: this.buy.id
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.$router.push('/plans/order/' + response.data.order.id);
            } else {
              this.$toast.error(response.data.message);
            }
          })
          .catch((error) => {
            if (error) {
              this.buy.modal = false;
              this.buy.loading = false;
            }
          })
          .finally(() => {
            this.buy.modal = false;
            this.buy.loading = false;
          });
      }
    },
    changePlan() {
      this.plan = null;
      this.monthlies.total = 0;
      this.monthlies.items = null;
    },
    showRenew(plan) {
      this.renew.modal = false;
      this.renew.modal = true;
      this.renew.id = plan.id;
      this.renew.name = plan.name;
      this.renew.description = plan.description;
      this.renew.value = plan.value;
    },
    renewPlan() {
      if (this.renew.id) {
        this.renew.loading = true;

        api
          .get("plans/renew")
          .then((response) => {
            if (response.data.status == "success") {
              this.$router.push('/plans/order/' + response.data.order.id);
            } else {
              this.$toast.error(response.data.message);
            }
          })
          .catch((error) => {
            if (error) {
              this.renew.modal = false;
              this.renew.loading = false;
            }
          })
          .finally(() => {
            this.renew.modal = false;
            this.renew.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getPlans()
    this.getPlan()
    this.getMonthlies()
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ t('Meu Plano') }}</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-3 col-lg-3 col-xl-3">
        <Stat :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div v-if="!plan && !plans" class="text-center">
      <b-spinner class="ml-2 align-middle" variant="default" role="status"></b-spinner>
    </div>
    <div v-else-if="!plan && plans && monthlies && monthlies.total == 0">
      <h5 class="mb-4">Escolha a melhor opção para você</h5>
      <div class="row">
        <div class="col-lg-4" v-for="(plan, index) in plans" :key="index">
          <div class="card">
            <div class="card-body">
              <p class="mb-1">{{ plan.name }}</p>
              <h2 class="mb-1 text-default">{{ plan.description }}</h2>
              <h5 class="mb-4 text-dark">{{ plan.value | currency }} / mês</h5>
              <button class="btn btn-default btn-block text-uppercase" v-on:click="showBuy(plan)">
                Escolher
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="buy.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Confirmar compra</h4>
        <p>Confirma a compra do plano <strong>{{ buy.name }} – {{ buy.description }}</strong>?</p>
        <div class="d-flex justify-content-between">
          <button class="btn btn-default mr-3" v-on:click="buyPlan()" :disabled="this.buy.loading">
            Confirmar
            <b-spinner v-if="buy.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
          <button class="btn btn-outline-link" v-on:click="buy.modal = false">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>

    <div v-if="monthlies && monthlies.total > 0" id="dropdown" class="d-flex mb-4">
      <b-dropdown class="bg-white" variant="outline-light">
        <template #button-content>
          Ações
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-item v-on:click="changePlan()">Alterar plano</b-dropdown-item>
        <b-dropdown-item v-if="plan && plan.renew" v-on:click="showRenew(plan)" :disabled="plan.renew == 'no'">Renovar plano</b-dropdown-item>
      </b-dropdown>
    </div>

    <b-modal v-model="renew.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Confirmar renovação</h4>
        <p>Confirma a renovação do plano <strong>{{ renew.name }} – {{ renew.description }}</strong>?</p>
        <div class="d-flex justify-content-between">
          <button class="btn btn-default mr-3" v-on:click="renewPlan()" :disabled="this.renew.loading">
            Confirmar
            <b-spinner v-if="renew.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
          <button class="btn btn-outline-link" v-on:click="renew.modal = false">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>

    <div v-if="monthlies && monthlies.total > 0" class="card">
      <div class="card-header rounded-top">
        <h4 class="card-title mb-0">Minhas Faturas</h4>
      </div>
      <div class="card-body">
        <div class="error text-center min-vh-50" v-if="monthlies.errored">
          Pedimos desculpas, não podemos recuperar informações neste momento. Por favor, tente novamente mais tarde.
        </div>
        <div v-else-if="monthlies.items && monthlies.items.length == 0" class="empty text-center min-vh-50">
          <strong>Nenhuma registro encontrado.</strong>
        </div>
        <div v-else class="table-responsive">
          <b-table :items="monthlies.items" :fields="monthlies.fields" class="table-nowrap table-style table-hover min-vh-50" head-variant="light" :sort-by.sync="monthlies.sortBy"
            :sort-desc.sync="monthlies.sortDesc" :current-page="monthlies.currentPage" :per-page="monthlies.perPage" responsive>
            <template #cell(start)="row">
              {{ row.item.date.start }}
            </template>
            <template #cell(end)="row">
              {{ row.item.date.end }}
            </template>
            <template #cell(plan)="row">
              {{ row.item.plan.name }}
            </template>
            <template #cell(status)="row">
              <span v-if="row.item.status === 'pending'" class="badge badge-soft-danger font-size-12">PENDENTE</span>
              <span v-else-if="row.item.status === 'approved'" class="badge badge-soft-success font-size-12">APROVADO</span>
            </template>
            <template #cell(order)="row">
              <router-link tag="a" :to="'/plans/order/' + row.item.order.id" class="btn btn-default">
                <i class="bx bx-show font-size-18 align-middle mr-1"></i>
                Ver
              </router-link>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </Layout>
</template>